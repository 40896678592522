<template>
  <section class="product_article">
    <div class="container">
      <!-- <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a
          class="nav-item nav-link active"
          data-toggle="tab"
          href="#nav-home"
          aria-selected="true"
          role="tab"
          >Description</a
        >
        <a
          class="nav-item nav-link"
          data-toggle="tab"
          href="#nav-profile"
          aria-selected="false"
          role="tab"
          >Products Details</a
        >
        <a
          class="nav-item nav-link"
          data-toggle="tab"
          href="#nav-contact"
          aria-selected="false"
          role="tab"
          >Reviews</a
        >
      </div> -->

      <!-- <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-home">
          <p>
            {{ productDetails.description }}
          </p>
          <p>
            {{ productDetails.description }}
          </p>
          <p>
            {{ productDetails.description }}
          </p>
        </div>
        <div class="tab-pane fade" id="nav-profile">
          <p>
            {{ productDetails.description }}
          </p>
          <p>
            {{ productDetails.description }}
          </p>
        </div>
        <div class="tab-pane fade" id="nav-contact">
          <p>
            {{ productDetails.description }}
          </p>
          <p>
            {{ productDetails.description }}
          </p>
        </div>
      </div> -->
      <b-tabs content-class="mt-3">
        <b-tab title="Description" active>
          <div v-html="productDetails.description"></div>
          <!--<p>
            {{ productDetails.description }}
          </p>-->
        </b-tab>
        <b-tab title="Products Details"
          >
          <!--<div v-html="productDetails.description"></div>-->

        </b-tab>
        <b-tab title="Reviews">
          <!--<div v-html="productDetails.description"></div>-->

        </b-tab>
      </b-tabs>
    </div>
    <div></div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProductArticle",
  computed: {
    ...mapGetters(["productDetails"]),
  },
};
</script>

<style lang="css" scoped></style>
